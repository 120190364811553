<template>
  <form ref="form">
    <b-row>
      <b-col cols="12">
        <b-card title="Обробляємі сторони">
          <b-row>
            <b-col cols="6">
              <b-form-group
                  label="За довжиною"
                  label-for="p1"
              >
                <b-form-select size="sm" id="p1"
                               aria-describedby="input-p1"
                               :state="detailValidation('P1_')"
                               v-model.number="detail.p1">
                  <b-form-select-option value="0">Не обробляти</b-form-select-option>
                  <b-form-select-option value="1">Одна</b-form-select-option>
                  <b-form-select-option value="2">Дві</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="input-p1">
                  {{ detailValidationText('P1_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  label="За шириною"
                  label-for="p2"
              >
                <b-form-select size="sm" id="p2"
                               aria-describedby="input-p2"
                               :state="detailValidation('P2_')"
                               v-model.number="detail.p2">
                  <b-form-select-option value="0">Не обробляти</b-form-select-option>
                  <b-form-select-option value="1">Одна</b-form-select-option>
                  <b-form-select-option value="2">Дві</b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="input-p2">
                  {{ detailValidationText('P2_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Оклеювання кромкою"
                  label-for="edge_template"
              >
                <b-form-select size="sm"
                               :style="{color:edges.find(el => el.ref === detail.edge_template)?.color || 'black'}"
                               aria-describedby="input-edge_template"
                               :state="detailValidation('EdgeA1_')"
                               id="edge_template" v-model="detail.edge_template">
                  <b-form-select-option :value="null">Ні</b-form-select-option>
                  <b-form-select-option v-for="(edge, i) in edges" :value="edge.ref" :key="i">
                    {{ (i + 1) + ' ' }} {{ edge.full_name }}
                  </b-form-select-option>
                </b-form-select>
                <b-form-invalid-feedback id="input-edge_template">
                  {{ detailValidationText('EdgeA1_') }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-file
                  accept="image/*" size="sm"
                  v-model="detail.file"
                  placeholder="Оберіть файл"
              ></b-form-file>
            </b-col>
            <b-col cols="12">
              <b-form-group
                  label="Примітка"
                  label-for="note"
              >
                <b-form-input
                    id="note" size="sm"
                    v-model="detail.note"
                ></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card>
      </b-col>

    </b-row>

  </form>
</template>

<script>
import validation from "@/mixins/BaseTemplate"

export default {
  mixins: [validation],
  props: [
    'edges',
    'detail'
  ],
  computed: {
    resp() {
      return this.$store.state.data.response;
    },
  },
  watch: {
    detail: function (newValue, oldValue) {
      this.$root.$emit('update-detail', this.detail);
    }
  },
  name: "Template_6"
}
</script>

<style scoped>

</style>