<template>


  <div>
    <div class="svg"></div>
    <template_2 v-if="detail.template === 2" :detail="detail" :edges="edges"></template_2>
    <template_3 v-if="detail.template === 3" :detail="detail" :edges="edges"></template_3>
    <template_4 v-if="detail.template === 4" :detail="detail" :edges="edges"></template_4>
    <template_5 v-if="detail.template === 5" :detail="detail" :edges="edges"></template_5>
    <template_6 v-if="detail.template === 6" :detail="detail" :edges="edges"></template_6>
    <template_7 v-if="detail.template === 7" :detail="detail" :edges="edges"></template_7>
    <template_8 v-if="detail.template === 8" :detail="detail" :edges="edges"></template_8>
  </div>

</template>

<script>
import * as d3 from "d3";
import template from "@/app/visualise/template_1";
import Template_2 from "@/views/Order/DetailForm/Template_2";
import Template_3 from "@/views/Order/DetailForm/Template_3";
import Template_4 from "@/views/Order/DetailForm/Template_4";
import Template_5 from "@/views/Order/DetailForm/Template_5";
import Template_6 from "@/views/Order/DetailForm/Template_6";
import Template_7 from "@/views/Order/DetailForm/Template_7";
import Template_8 from "@/views/Order/DetailForm/Template_8";

export default {
  data() {
    return {
      tmp: 0,
      template: template,
      body: null,
      svg: null,
      sc: null,
      H: 250,
      W: 500,
      plus: 12,
    }
  },
  components : {
    Template_2, Template_3, Template_4,
    Template_5, Template_6, Template_7,
    Template_8,
  },
  computed: {
    detail() {
      return this.$store.state.data.detail;
    },
    edges() {
      return this.$store.state.data.edges;
    },
    materials() {
      return this.$store.state.data.materials;
    },
  },
  watch: {
    detail: {
      handler(value, old) {
        if (this.detail) this.visualize();
      },
      deep: true
    },
    edges: {
      handler(value, old) {
        if (this.detail) this.visualize();
      },
      deep: true
    }
  },
  methods: {
    visualize() {
      //Очистка
      this.svg.selectAll("*").remove();
      if (this.detail.material) {
        //Меняем
        if (this.template.config == undefined || null == this.template.config.d3 || this.tmp != this.detail.template) {
          import('@/app/visualise/template_' + this.detail.template).then((obj) => {
            this.template = obj.default;

            this.template.init({
              d3: d3,
              svg: this.svg,
              H: this.H,
              W: this.W,
              plus: this.plus,
            });

            this.tmp = this.detail.template;

            this.template.draw(this.detail, this.edges, this.materials);

          }).catch(err => console.log(err));
        } else {
          this.template.draw(this.detail, this.edges, this.materials);
        }
      }
    },
  },
  created() {
    this.$root.$on('update-detail', detail => {
      this.$store.commit('data/SET_DETAIL', detail);
    });
  },
  mounted() {
    this.body = d3.select('.svg');
    this.svg = this.body.append('svg');
    // this.svg.style('border', '1px solid blue');
    if (this.detail) this.visualize();


    this.$root.$on('update', i => {
      this.$forceUpdate();
    });
  }
}
</script>
