<template>
  <div class="modal-list">
    <table class="table search-response" v-if="responseData.length > 0 || replaceItemAnalogs != undefined">
      <tbody>
      <tr class="table-search-item" v-if="replaceItemAnalogs != undefined">
        <td class="search__image">
          <div class="search__image-wrap">
            <img height="70" :src="appconfig.Point+`/`+replaceItemAnalogs.image" alt="">
            <figcaption v-if="replaceItemAnalogs.on_order" class="on_order_label">Під замовлення</figcaption>
          </div>
        </td>
        <td class="search__name">
          <p class="item__name">
            {{ replaceItemAnalogs.full_name }}
          </p>
          <p>
            <span class="text-muted">  Код: </span>{{ replaceItemAnalogs.code }}
            <span class="text-muted"> | </span>
            <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="replaceItemAnalogs.quantity == 0"> Термін постачання до: {{
                           replaceItemAnalogs.terms
                         }}</span>
                        <span class="item__sku--instock" v-else="replaceItemAnalogs.quantity > 0"> В наявності</span>
                    </span>
            <button class="button button__small"
                    v-if="replaceItemAnalogs.analogs !== undefined && replaceItemAnalogs.analogs.length > 0"
                    @click.prevent="takeItemAnalogs(replaceItemAnalogs)">
              Аналоги
            </button>
          </p>
        </td>
        <td class="search__price">
          <div class="item__sku--price">
            <div class="item__price-wrap" v-if="replaceItemAnalogs.price > 0">
                <span class="item__price" v-if="what == 'materials'">
                  {{ (+replaceItemAnalogs.p).toFixed(2) }}
                </span>
              <span class="item__price" v-else>
                    {{ replaceItemAnalogs.price }}
                </span>
              <span class="item__price-cur">
                  {{ replaceItemAnalogs.cy_name }}
                </span>
              <span v-if="what == 'materials'" class="item__rat io">
                   / {{ replaceItemAnalogs.base_unit_name }}
                </span>
              <span v-else class="item__ratio">
                   / {{ replaceItemAnalogs.sale_unit_name }}
                </span>
            </div>

          </div>
        </td>
        <td class="search__action">
          <div class="d-flex justify-content-between">
            <button class="button" :id="'_'+replaceItemAnalogs.ref" @click="takeItem(replaceItemAnalogs)"
                    :disabled="exists(replaceItemAnalogs.ref)">
              Обрати
            </button>
          </div>
        </td>
      </tr>

      <tr class="table-search-item" v-for="(item, index) in responseData" :key="index">
        <td class="search__image">
          <div class="search__image-wrap">
            <img height="70" loading="lazy" :src="appconfig.Point+`/`+item.image" alt="">
            <figcaption v-if="item.on_order" class="on_order_label">Під замовлення</figcaption>
          </div>
        </td>
        <td class="search__name">
          <p class="item__name">
            {{ item.full_name }}
          </p>
          <p>
            <span class="text-muted">  Код: </span>{{ item.code }}
            <span class="text-muted"> | </span>
            <span class="item__sku">
                       <span class="item__sku--terms"
                             v-if="item.quantity == 0"> Термін постачання до: {{ item.terms }}</span>
                        <span class="item__sku--instock" v-else> В наявності</span>
                    </span>
            <button class="button button__small"
                    v-if="item.analogs !== undefined && item.analogs.length > 0"
                    @click.prevent="takeItemAnalogs(item)">
              Аналоги
            </button>
          </p>
        </td>
        <td class="search__price">
          <div class="item__sku--price">
            <div class="item__price-wrap" v-if="item.price > 0">
                <span class="item__price">
                  {{ (+item.p).toFixed(2) }}
                </span>

              <span class="item__price-cur">
                  {{ item.cy_name }}
                </span>
              <span v-if="what == 'materials'" class="item__rat io">
                   / {{ item.base_unit_name }}
                </span>
              <span v-else class="item__ratio">
                   / {{ item.sale_unit_name }}
                </span>
            </div>

          </div>
        </td>
        <td class="search__action">
          <div class="d-flex" v-if="item.price > 0">
            <button class="button" :id="'_'+item.ref" @click.prevent="takeItem(item)"
                    :disabled="exists(item.ref)">
              Обрати
            </button>
            <button v-if="item.recommends != undefined  && item.recommends.length > 0 && what === 'materials'"
                    class="button button__grey" :id="'_'+item.ref" @click.prevent="takeItemEdges(item)">
              Кромки
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <span class="search--empty" v-else> Даних немає </span>

  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: "search-render",
  props: ['what', 'responseData'],
  data() {
    return {
      appconfig: appconfig,
      details: this.$store.state.data.details,
    }
  },
  computed: {

    materials() {
      return this.$store.state.data.materials;
    },
    edges() {
      return this.$store.state.data.edges;
    },
    replaceItemAnalogs() {
      let item = undefined;
      let replace = this.$store.state.data.replace;
      if (replace !== null) {
        if (this.responseData.filter(el => el.ref === replace).length === 0 || this.responseData.length == 0) {
          item = this[this.what].find(el => el.ref === replace);
        }
      }
      return item != undefined && item.analogs.length > 0 ? item : undefined;
    }
  },
  methods: {

    exists(ref) {
      return this[this.what].find(el => el.ref === ref) ? 'disabled' : false
    },
    takeItem(item) {
      let elem = this[this.what].find(el => el.ref === item.ref);
      if (elem) return;
      let replace = this.$store.state.data.replace;

      //Замена или добавление
      if (replace != null) {
        let toReplaceIndex = this[this.what].findIndex(el => el.ref === replace);
        if (confirm('Замінити ' + this[this.what][toReplaceIndex].full_name + ' на ' + item.full_name)) {
          let current = this[this.what][toReplaceIndex]
          this.replaceInDetails(item); //change in details
          replace = null;
          item = {...item, color_show: false, color: this.$store.getters['data/colors'][this[this.what].length + 1]}
          if (this.what === 'materials') {
            let defaultMaterial = this.$store.getters['data/defaultMaterial'];
            if (defaultMaterial.ref === current.ref) {
              this.$store.commit('data/SET_DEFAULT_MATERIALS', item);
            }
            this[this.what][toReplaceIndex] = item;
            this.$store.commit('data/SET_MATERIALS', this[this.what]);
          }
          if (this.what === 'edges') {
            let defaultEdge = this.$store.getters['data/defaultEdge'];
            if (defaultEdge.ref === current.ref) {
              this.$store.commit('data/SET_DEFAULT_EDGES', item);
            }
            this[this.what][toReplaceIndex] = item;
            this.$store.commit('data/SET_EDGES', this[this.what]);
          }
          document.getElementById('_' + item.ref).setAttribute("disabled", "disabled");
        }


      } else {
        // this[this.what].push(item);
        document.getElementById('_' + item.ref).setAttribute("disabled", "disabled");
        if (this.what === 'materials') {
          this.$store.commit('data/ADD_MATERIALS', item);
        }
        if (this.what === 'edges') {
          this.$store.commit('data/ADD_EDGES', item);
        }
      }

    },
    replaceInDetails(item) {
      let replace = this.$store.state.data.replace;

      if (this.what === 'materials') {
        this.details
            .filter(el => el.material === replace)
            .forEach(i => {
              i.material = item.ref;
            });
      } else if (this.what === 'edges') {
        this.details
            .filter(el => el.edge_t === replace)
            .forEach(i => {
              i.edge_t = item.ref;
            });
        this.details
            .filter(el => el.edge_b === replace)
            .forEach(i => {
              i.edge_b = item.ref;
            });
        this.details
            .filter(el => el.edge_l === replace)
            .forEach(i => {
              i.edge_l = item.ref;
            });
        this.details
            .filter(el => el.edge_r === replace)
            .forEach(i => {
              i.edge_r = item.ref;
            });
        this.details
            .filter(el => el.edge_template === replace)
            .forEach(i => {
              i.edge_template = item.ref;
            });
      }
    },
    takeItemEdges(item) {
      this.$root.$emit('edges_change', {'edges': item.recommends || [], 'material': item, what: this.what});
      $('#recommendModal').modal();
      $('#searchModal').modal('hide');
      this.$store.commit('data/SET_MODAL', 'searchModal');
    },
    takeItemAnalogs(item) {
      this.$root.$emit('analogs_change', {'analogs': item.analogs || [], 'material': item, what: this.what});
      $('#searchModal').modal('hide');
      $('#analogsModal').modal();
      this.$store.commit('data/SET_MODAL', 'searchModal');

    }
  }
}
</script>

<style scoped>
.table td {
  overflow: hidden;
  vertical-align: middle;
}

p {
  letter-spacing: 0.05px;
  word-spacing: -0.93px;
}

.item__sku > span {
  position: relative;
  color: #19459d;
}

.item__sku--terms::before, .item__sku--terms::before {
  content: url('~@/assets/img/car_truck.svg');
  margin-right: 5px;
  top: 2px;
  position: relative;
}

.item__sku--instock::before, .item__sku--instock::before {
  content: '';
  background: url('~@/assets/img/check-circle.svg') no-repeat center;
  margin-right: 5px;
  width: 18px;
  height: 16px;
  display: inline-block;
  top: 3px;
  position: relative;
}

.search__action {
  display: flex;
  align-items: center
}

.search__action div {
  gap: 15px;
  align-items: center
}

.table-search-item {
  display: flex;
  border-top: 1px solid #dee2e6;
}

.table-search-item td {
  border-top: unset;
}

</style>