function getDefaultState() {
    return {
        detail: {},
        details: [],
        materials: [],
        edges: [],
        screeds: [],
        detailsNames: [],
        defaultMaterial: null,
        modal: '',
        defaultEdges: null,
        order: null,
        replace: null,
        response: null,
        responseDebug: null,
        colors: [
            '#FF0000', '#FF7F00', '#00FF00', '#00FFFF',
            '#0000FF', '#8B00FF', '#A52A2A', '#FFC0CB', '#808080',
            '#000000', '#008080', '#FFD700', '#4B0082', '#006400',
            '#FF1493', '#00CED1', '#7FFF00', '#DA70D6', '#FF4500'
        ]
    };
}

function procesK(detail) {
    detail.k1 = (detail.edge_b != null) + (detail.edge_t != null);
    detail.k2 = (detail.edge_r != null) + (detail.edge_l != null);
    return detail;
}

export default {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        order(state) {
            return state.order
        },
        materials(state) {
            return state.materials
        },
        colors(state) {
            return state.colors
        },
        detail(state) {
            return state.detail
        },
        edges(state) {
            return state.edges
        },
        screeds(state) {
            return state.screeds
        },
        detailsNames(state) {
            return state.detailsNames
        },
        defaultMaterial(state) {
            let check = false;
            if(state.defaultMaterial) {
                check = state.materials.find((el) => el.ref === state.defaultMaterial.ref)
            }
            return check ? state.defaultMaterial : (state.materials[0] || null);
        },
        defaultEdge(state) {
            let check = false;
            if(state.defaultEdges) {
                check = state.edges.find((el) => el.ref === state.defaultEdges.ref)
            }
            return check ? state.defaultEdges : (state.edges[0] || null);
        }
    },
    mutations: {
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        SET_DETAIL(state, value) {
            state.detail = value;
        },
        SET_MODAL(state, value) {
            state.modal = value;
        },
        SET_RESPONSE(state, value) {
            state.response = value;
        },
        SET_RESPONSE_DEBUG(state, value) {
            state.responseDebug = value;
        },
        SET_ORDER(state, value) {
            state.order = value;
        },
        SET_REPLACE(state, value) {
            state.replace = value;
        },
        SET_DETAILS(state, value) {
            state.details = value;
            if(state.details != null) {
                state.details = state.details.map(detail => procesK(detail))
            }
        },
        SET_DEFAULT_MATERIALS(state, value) {
            state.defaultMaterial = value;
        },
        SET_DEFAULT_EDGES(state, value) {
            state.defaultEdges = value;
        },
        SET_MATERIALS(state, value) {
            state.materials = value
        },
        ADD_MATERIALS(state, value) {
            value['color'] = state.colors[state.materials.length + 1]
            state.materials.push(value)
            state.materials = state.materials.map(m => ({
                ...m,
                color_show: false,
            }));
        },
        REMOVE_MATERIAL(state, value) {
            state.materials.splice(value, 1)
        },
        REMOVE_NAME(state, value) {
            state.detailsNames.splice(value, 1)
        },
        SET_EDGES(state, value) {
            state.edges = value
        },
        ADD_EDGES(state, value) {
            value['color'] = state.colors[state.edges.length + 1]
            state.edges.push(value)
            state.edges = state.edges.map(m => ({
                ...m,
                color_show: false,
            }));
        },
        SET_SCREEDS(state, value) {
            state.screeds = value.length > 0 ? value.sort((a, b) => a.No - b.No).filter(i => i.No != 1) : value;
        },
        SET_NAMES(state, value) {
            state.detailsNames = value
        },
        ADD_SCREED(state, value) {
            state.screeds.push(value)
        },
        REMOVE_SCREED(state, value) {
            let currentScreed = state.screeds[value];

            state.details.map((detail) => {
                if(detail.screed == currentScreed.No) {
                    detail.screed = 0;
                }
                return detail;
            });

            state.screeds.splice(value, 1)
        },
        TOGGLE_COLOR_PICKER(state, materialRef) {
            state.materials.forEach(m => {
                m.color_show = m.ref === materialRef ? !m.color_show : false;
            });
        },
        CLOSE_ALL_PICKERS(state) {
            state.materials.forEach(m => m.color_show = false);
        },
        UPDATE_MATERIAL_COLOR(state, { ref, color }) {
            const material = state.materials.find(m => m.ref === ref);
            if (material) material.color = color;
        }
    }
}